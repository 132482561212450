import axios from 'axios'
import { refreshTokens } from './userAPI'

export const axiosInstance = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
    },
    params: {
        excludeRoot: false,
    },
    withCredentials: true,
})

// Automatically add access token to all requests
axiosInstance.interceptors.request.use(
    async (apiConfig) => {
        if (!apiConfig.headers) return apiConfig
        const token = localStorage.getItem('token')
        apiConfig.headers.Authorization = `Bearer ${token}`
        return apiConfig
    },
    (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    async (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config

        if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true
            try {
                const response = await refreshTokens()
                const access = response.data.token
                if (!access) {
                    localStorage.removeItem('token')
                    return Promise.reject(error)
                }
                localStorage.setItem('token', access)
                return axiosInstance(originalRequest, {
                    headers: {
                        Authorization: `Bearer ${access}`,
                    },
                })
            } catch (error) {
                localStorage.removeItem('token')
                console.log('Refresh token not found or invalid refresh token')
            }
        }

        return Promise.reject(error)
    }
)
